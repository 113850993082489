/**
 * @package     Joomla.Site
 * @subpackage  Templates.noName
 *
 * @copyright   Copyright (C) 2020 noName. All rights reserved.
 * @license     GNU General Public License version 2 or later; see LICENSE.txt
 */
import $ from 'jquery';
import { Collapse, Modal } from 'bootstrap';
import slick from 'slick-carousel';
import(/* webpackPrefetch: true */ '../utils/header.js');

import(/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ '../utils/swiper.js');
const WOW = require('wowjs');

const FontFaceObserver = require('fontfaceobserver');

const fontSaira = new FontFaceObserver('SairaCondensed-Regular');
const fontSairaMedium = new FontFaceObserver('SairaCondensed-Medium');
const fontSairaSemiBold = new FontFaceObserver('SairaCondensed-SemiBold');
const fontSairaBold = new FontFaceObserver('SairaCondensed-Bold');
Promise.all([
  fontSaira.load(),
  fontSairaMedium.load(),
  fontSairaSemiBold.load(),
  fontSairaBold.load(),
]).then(function () {
  document.documentElement.style.setProperty(
    '--bs-font-sans-serif',
    'SairaCondensed-Regular, sans-serif'
  );
  document.documentElement.style.setProperty(
    '--bs-font-sans-serif-semiBold',
    'SairaCondensed-SemiBold, sans-serif'
  );
  document.documentElement.style.setProperty(
    '--bs-font-sans-serif-medium',
    'SairaCondensed-Medium, sans-serif'
  );
  document.documentElement.style.setProperty(
    '--bs-font-sans-serif-bold',
    'SairaCondensed-Bold, sans-serif'
  );
});
$('#mobileNavi').click(function () {
  $('.main_menu').toggleClass('open');
  $(this).toggleClass('navi-open');
});
$(window).scroll(function () {
  if ($(this).scrollTop() > 50) {
    $('header').addClass('small');
  } else {
    $('header').removeClass('small');
  }
  if ($(this).scrollTop() > 50) {
    $('.main_menu').addClass('small');
  } else {
    $('.main_menu').removeClass('small');
  }
});
window.wow = new WOW.WOW({});
window.wow.init();

$(function () {
  $('#me-general-contractor-slider').slick({
    slidesToShow: 1,
    arrows: true,
    autoplaySpeed: 2500,
    prevArrow: $('.me_general_contractor .prev'),
    nextArrow: $('.me_general_contractor .next'),
    loop: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('.me-list-img-slider').slick({
    slidesToShow: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2500,
    loop: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('#safety-culture-slider').slick({
    slidesToShow: 1,
    arrows: true,
    dots: true,
    autoplaySpeed: 2500,
    prevArrow: $('.safety_culture .prev'),
    nextArrow: $('.safety_culture .next'),
    loop: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
