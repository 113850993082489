import $ from 'jquery';
import slick from 'slick-carousel';
$('.home-slider').slick({
  slidesToShow: 1,
  dots: true,
  arrows: true,
  // adaptiveHeight: true,
  // autoplay: true,
  autoplaySpeed: 2500,
});
